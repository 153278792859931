import React, {useState,useEffect, useCallback} from 'react'  
import {
  Card,
  Tabs
} from "@shopify/polaris"
import ProductDesktopPreview from './productDesktopPreview'
  
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


const tabs =
[
  {
    id: 'desktop-preview',
    content: 'Desktop cart preview',
    panelID: 'desktop-preview-content',
  },
  {
    id: 'mobile-preview',
    content: 'Mobile cart preview',
    panelID: 'mobile-preview-content',
  }
];
  
function ProductPreview(props) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0) 
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTabIndex(selectedTabIndex),
    [],
  ) 

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div style={{maxHeight: windowDimensions.height ? windowDimensions.height : '100%',}}>
      <Card sectioned>  
        <Tabs tabs={tabs} selected={selectedTabIndex} onSelect={handleTabChange} fitted>
          <div style={{paddingTop: '20px'}}>
            { selectedTabIndex === 0 &&
            <ProductDesktopPreview {...props}/>
            }
            { selectedTabIndex === 1 &&
            <ProductDesktopPreview {...props}/>
            }
          </div>
        </Tabs>
      </Card>
    </div>
  )
}

export default ProductPreview;